/* eslint-disable react/prop-types */
import { Image, Modal, Text } from '@atoms/index';
import { useInvestorContext } from '@context/InvestorContextProvider';
import { loginUserV2, resendContextOtp, verifyContextOtp } from '@services/session.service';
import { getReferralCode, setAuth } from '@services/identity.service';
import { getPhoneInputStyle } from '@helpers/constants';
import { HeadTagData } from '@helpers/headTagData';
import {
    appendCountryCodeAndFormatMobile,
    handleKeyDown,
    maskEmail,
    saveItemToLocalStorage,
    triggerCustomEvent,
} from '@helpers/miscelleanous';
import { isNotIfa, isValidResponseEntity } from '@helpers/utils';
import { getMapping } from '@services/survey.service';
import {
    getMultipleData,
} from '@services/users.service';
import { Button } from '@ui/atoms/design-system';
import useOtpInput from '@ui/hooks/useOtpInput';
import NextHeadTag from '@ui/molecules/NextHeadTag';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { otpContextEnum } from '@ui/helpers/enum';
import { otpExpiredErrorWord, otpLength } from '@ui/helpers/constants';
import { gaEventNameEnum, taxOptimiserEvents } from '@helpers/enums/gaEventNameEnum';
import useUserLevelNudges from 'hooks/functional/useUserLevelNudges';
import { userLevelNudgeType } from '@helpers/enums/userEnums';
import { KycStatus } from '@helpers/enums/kycEnums';
import {logEvent, setUserData,moengageEventConstants} from "@services/moengage";
import { loadDigioSdk } from '@lib/load-digio-sdk';
import {
    taxOptimiserBrandName,
    taxOptimiserRoutes,
} from '@components/TaxOptimiser/helpers/constants';
import { getTaxOptimiserResult } from '@services/tax-optimiser.service';
import { taxHarvesterRoutes } from '@components/TaxHarvester/helper/constants';

const VerifyOtpModal = ({
    show,
    onClose,
    mobileNumber,
    editMobileNumber,
    countryCode,
    opportunityId,
    referralCode,
    referralEntity,
    userInput,
    multipleLogin,
    otpModes,
    onSessionCreationError,
    isTaxOptimiserLogin,
}) => {
    const router = useRouter();
    const otpExpiresAfter = 60;
    const [seconds, setSeconds] = useState(otpExpiresAfter);
    const [message, setMessage] = useState('');
    const [multipleMessage, setMultipleMessage] = useState('');
    const [otpMessage, setOtpMessage] = useState('OTP sent successfully!');
    const [loading, setLoading] = useState(false);
    const [selectAccountModal, setMultipleAccountModal] = useState(false);
    const [multipleInvestor, setMultipleLoginData] = useState([]);
    const [loginButtonState, setLoginButtonState] = useState(false);
    const { width } = useWindowDimensions();
    const phoneInputStyle = getPhoneInputStyle(
        width,
        false,
        isTaxOptimiserLogin
    );
    const { otpValue, setOtpValue, OtpInputComponent, setDisabled, setError } =
        useOtpInput({
            setMessage,
            isTaxOptimiserLogin,
        });
    const timeoutRef = useRef();

    const { setMenus, checkEligibility } = useInvestorContext();
    const { handleSubmit } = useForm();
    const isReferAndEarnPage = router.pathname === '/referandearn';
    const [tempAuth, setTempAuth] = useState();
    const [isResendButtonLoading, setIsResendButtonLoading] = useState(false);
    const [otpId, setOtpId] = useState();

    const {
        fetchNudges
    } = useUserLevelNudges({ userId: null });

    const editMobile = () => {
        setOtpValue('');
        setMessage('');
        editMobileNumber();
    };

    const onPanSubmit = async () => {
        setLoading(true);
        const response = await loginUserV2(mobileNumber, [otpId], userInput, tempAuth.userid, tempAuth.token);
        if (isValidResponseEntity(response)) {
            await setAuthData(response);
            await setUserData({auth: response?.entity});
            logEvent({eventName: moengageEventConstants.LOGIN_SUCCESS,});
            triggerCustomEvent(gaEventNameEnum.LOGIN_VERIFY_OTP, { mobile: mobileNumber });
        } else {
            setLoading(false);
            onSessionCreationError();
        }
    };

    const onSubmit = async () => {
        setDisabled(true);
        setLoading(true);
        let response, verifyResponse;
        //login
        verifyResponse = await verifyContextOtp({ mobile: mobileNumber, context: otpContextEnum.AUTHENTICATION, otp: otpValue, });
        if (verifyResponse.status) {
            setOtpId(verifyResponse.entity.id);
            response = await loginUserV2(mobileNumber, [verifyResponse.entity.id], { ...userInput, countryCode: userInput.countryCodeWithPrefix });
            if (isValidResponseEntity(response)) {
                if (multipleLogin) {
                    const multipleAccountResponse = await getMultipleData(
                        userInput.mobile,
                        response?.entity
                    );
                    if (multipleAccountResponse?.entity) {
                        setLoading(false);
                        setTempAuth(response?.entity);
                        setMultipleAccountModal(true);
                        let loginData =
                            multipleAccountResponse.entity.multipleLoginData.filter(
                                (data) => data.isActive
                            );
                        setMultipleLoginData(loginData);
                    }
                    else {
                        setLoading(false);
                        setMessage(multipleAccountResponse?.message[0] || 'Something went wrong');
                    }
                    setDisabled(false);
                } else {
                    await setAuthData(response);
                    await setUserData({auth: response?.entity});
                    logEvent({eventName: moengageEventConstants.LOGIN_SUCCESS,});
                    triggerCustomEvent(
                        gaEventNameEnum.LOGIN_VERIFY_OTP,
                        { mobile: mobileNumber }
                    );
                }
            } else {
                setLoading(false);
                setDisabled(false);
                onSessionCreationError();
            }
        }
        else {
            setLoading(false);
            setMessage(verifyResponse.message);
            setError(true);
            if (verifyResponse.message[0]?.includes(otpExpiredErrorWord)) {
                setDisabled(true);
                setSeconds(0);
            } else {
                setDisabled(false);
            }
        }
    };

    const setAuthData = async (response) => {
        const user = response?.entity;
        setAuth(user);
        await setMenus();
        await checkEligibility(user.id);
        const mapData = await getMapping();
        if (isValidResponseEntity(mapData)) {
            saveItemToLocalStorage('survey', mapData?.entity);
        }
        if (router?.asPath === '/refer-and-earn' && isNotIfa(user)) {
            window.location.reload('/refer-and-earn');
            return;
        }
        navigateTo(user);
    };

    /**
     * Post signup we require to fire conversion pixels,
     * and for this we have to identify if it was a signup or login
     * for this we are using isreturnvisit and referrer code query string in GTM
     * If we change the landing page, use the same query params for that page as well
     */
    const navigateTo = async (user) => {
        const { kycStatus } = user;
        if (router?.asPath === taxHarvesterRoutes.landingPage) {
            window.location.reload();
            return;
        }
        if (router?.asPath?.includes(taxOptimiserRoutes.landingPage)) {
            const taxOptimiserResult = await getTaxOptimiserResult(user.id);
            triggerCustomEvent(
                taxOptimiserEvents.VERIFY_OTP_TAX_OPTIMISER
            );
            logEvent({eventName: moengageEventConstants.LOGIN_SUCCESS_TAX_OPTIMISER,});
            if (!taxOptimiserResult?.entity) {
                window.location.reload();
            } else {
                router.push(taxOptimiserRoutes.dashboard);
            }
            return;
        }
        const userLevelNudges = await fetchNudges(user.userid);
        const shouldRedirectToQuestionnaire = userLevelNudges.nudges?.findIndex(nudge => nudge.nudgeType === userLevelNudgeType.USER_QUESTIONNAIRE) >= 0
        referralCode = getReferralCode();
        const redirect = router?.query?.redirect || '';
        const deeplink = router?.query?.deeplink || '';
        const internalredirect = router?.query?.internalredirect || '';
        let basePath = '';
        let queryParams = '';

        if (referralCode) {
            queryParams =
                referralEntity === 'usoc'
                    ? addParam(queryParams, `usoc=${referralCode}&isreturnvisit=true`)
                    : addParam(queryParams, `referrer=${referralCode}&isreturnvisit=true`);
        }

        if (redirect && (deeplink || internalredirect)) {
            basePath = redirect;
        } else if (shouldRedirectToQuestionnaire) {
            basePath = '/questionnaire';
            if (!referralCode) {
                queryParams = addParam(queryParams, `isreturnvisit=true`);
            }
            if (isReferAndEarnPage) {
                queryParams = addParam(queryParams, 'referandearn=true');
            } else if (opportunityId) {
                queryParams = addParam(
                    queryParams,
                    `opportunityId=${opportunityId}`
                );
            }
        } else if (!kycStatus || kycStatus === KycStatus.PENDING) {
            basePath = '/welcome-back';
            if (isReferAndEarnPage) {
                queryParams = addParam(queryParams, 'referandearn=true');
            } else if (opportunityId) {
                queryParams = addParam(
                    queryParams,
                    `opportunityId=${opportunityId}`
                );
            }
        } else {
            basePath = isReferAndEarnPage
                ? `/refer-and-earn`
                : opportunityId
                    ? `/opportunity/${opportunityId}`
                    : `/opportunities`;
        }
        loadDigioSdk();
        router.push(`${basePath}${queryParams}`);
    };

    const addParam = (queryParams, param) => {
        return queryParams ? queryParams + `&${param}` : `?${param}`;
    };

    const handlePanInputChange = (documentId) => {
        documentId?.map((document) =>
            document !== null
                ? (userInput.documentId = document)
                : 'Please select at least 1'
        );
        setMultipleMessage('');
        setLoginButtonState(true);
    };

    const onResendOtp = async () => {
        setOtpValue('');
        setIsResendButtonLoading(true);
        setError(false);
        setMessage('');
        const response = await resendContextOtp({
            mobile: mobileNumber,
            context: otpContextEnum.AUTHENTICATION
        });
        if (response.status) {
            setMessage('');
            setOtpMessage('OTP sent successfully!');
            setDisabled(false);
            setSeconds(otpExpiresAfter);
        } else {
            setOtpMessage('Failed to resend the OTP. Try again!');
        }
        setIsResendButtonLoading(false);
    };

    useEffect(() => {
        if (seconds > 0) {
            timeoutRef.current = setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            setOtpMessage('');
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [seconds]);

    return (
        <>
            <NextHeadTag {...HeadTagData?.['#VerifyOtpModal']} />
            {selectAccountModal && (
                <Modal
                    show={selectAccountModal}
                    width="max-w-[440px]"
                    onClose={loading ? null : onClose}
                    label={
                        isTaxOptimiserLogin
                            ? `Login to ${taxOptimiserBrandName}`
                            : 'Login'
                    }
                    labelBorderClass="border-none"
                    labelParaClass={`${
                        isTaxOptimiserLogin
                            ? 'text-basicWhite h6-semibold sm:h5-semibold'
                            : 'text-primary-500 h5-semibold'
                    }`}
                    labelPadding="px-4 md:px-6 pt-4"
                    paddingClass="p-4 md:p-6"
                    roundedClass="rounded-2xl"
                    labelSize={' '}
                    isDarkTheme={isTaxOptimiserLogin}
                    modalColorClass={
                        isTaxOptimiserLogin
                            ? 'text-basicWhite bg-basicBlack border border-gray-500'
                            : 'text-basicBlack bg-basicWhite'
                    }
                    mainWrapperClassName={
                        isTaxOptimiserLogin ? 'backdrop-blur-sm inset-0' : ''
                    }
                    children={
                        <form onSubmit={handleSubmit(onPanSubmit)}>
                            <label
                                className={`block w-full text-left ${
                                    isTaxOptimiserLogin
                                        ? 'text-basicWhite'
                                        : 'text-gray-900'
                                }`}
                            >
                                {' '}
                                Mobile Number{' '}
                            </label>
                            <div
                                className={`mt-2 ${
                                    isTaxOptimiserLogin
                                        ? 'text-basicWhite'
                                        : 'text-gray-900'
                                } 
                                p5-regular
                            `}
                            >
                                <PhoneInput
                                    value={userInput.mobile}
                                    onKeyDown={handleKeyDown}
                                    onFocus={(e) => e.preventDefault()}
                                    onClick={(e) => e.preventDefault()}
                                    inputClass={'px-5 h-full text-gray-900'}
                                    disableDropdown={true}
                                    containerClass={`h-12 mb-2 flex w-full ${
                                        isTaxOptimiserLogin
                                            ? 'tax-optimiser-phone-input'
                                            : 'basic-phone-input'
                                    }`}
                                    containerStyle={
                                        phoneInputStyle.containerStyle
                                    }
                                    inputStyle={phoneInputStyle.inputStyle}
                                    buttonStyle={phoneInputStyle.buttonStyle}
                                    inputProps={{ autoFocus: false }}
                                    disabled={true}
                                />
                                You have {multipleInvestor.length} investor
                                records linked to your login ID. Please select
                                the account you wish to access.
                            </div>

                            <div className="w-full mt-3 mb-2 overflow-y-auto h-30">
                                {multipleInvestor?.map((investor, index) => (
                                    <div className="block" key={index}>
                                        <div
                                            className={`mb-3 ${
                                                isTaxOptimiserLogin
                                                    ? 'text-basicWhite'
                                                    : 'text-gray-900'
                                            }`}
                                        >
                                            <label className="flex items-center pt-1 ml-2 p5-medium">
                                                <input
                                                    className={`mr-2 ${
                                                        isTaxOptimiserLogin
                                                            ? 'checked:bg-primary-100 checked:!text-primary-100 bg-gray-200'
                                                            : 'checked:bg-primary-500 checked:!text-primary-500'
                                                    } shadow-focus-input-radios-none focus:!outline-none focus:border-none`}
                                                    type="radio"
                                                    name="pan selection"
                                                    value={investor.name}
                                                    onChange={() =>
                                                        handlePanInputChange(
                                                            investor?.documents?.map(
                                                                (document) =>
                                                                    document.type ===
                                                                        'Pan'
                                                                        ? document.documentId
                                                                        : null
                                                            )
                                                        )
                                                    }
                                                />
                                                {investor.name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {multipleMessage && (
                                <Text
                                    content={multipleMessage}
                                    className="mt-4 text-center text-red-900"
                                />
                            )}
                            <Button
                                id="verifyOtpButton"
                                buttonType="primary"
                                widthClass="w-full"
                                className={
                                    isTaxOptimiserLogin && loginButtonState === false
                                        ? 'bg-gray-400 text-gray-600'
                                        : ''
                                }
                                isLoading={loading}
                                disabled={
                                    loginButtonState === false && 'disabled'
                                }
                                buttonText="Login"
                            />
                        </form>
                    }
                />
            )}

            <Modal
                show={show && !selectAccountModal}
                onClose={onClose}
                width="max-w-[440px]"
                label="Verify OTP"
                labelBorderClass="border-none"
                labelParaClass={`h5-semibold ${
                    isTaxOptimiserLogin ? 'text-basicWhite' : 'text-primary-500'
                }`}
                labelPadding="px-4 md:px-6 pt-4"
                roundedClass="rounded-2xl"
                paddingClass="p-4 md:p-6"
                labelSize={' '}
                isDarkTheme={isTaxOptimiserLogin}
                modalColorClass={
                    isTaxOptimiserLogin
                        ? 'text-basicWhite bg-basicBlack border border-gray-500'
                        : 'text-basicBlack bg-basicWhite'
                }
                mainWrapperClassName={
                    isTaxOptimiserLogin ? 'backdrop-blur-sm inset-0' : ''
                }
                children={
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <div className="mb-4 text-center">
                                <Text
                                    content={`A ${otpLength} digit OTP has been sent to you at`}
                                    className={`mt-1 mb-2 p5-medium ${
                                        isTaxOptimiserLogin
                                            ? 'text-basicWhite'
                                            : 'text-gray-900'
                                    }`}
                                />
                                <div className="flex items-center justify-center">
                                    <Text
                                        content={appendCountryCodeAndFormatMobile(mobileNumber, countryCode)}
                                        className={`pr-2 p5-medium ${
                                            isTaxOptimiserLogin
                                                ? 'text-basicWhite'
                                                : 'text-basicBlack'
                                        }`}
                                    />
                                    <span
                                        onClick={editMobile}
                                        className="cursor-pointer"
                                    >
                                        <Image
                                            src={
                                                isTaxOptimiserLogin
                                                    ? '/images/edit-white.svg'
                                                    : '/images/edit.svg'
                                            }
                                            width="20"
                                            height="20"
                                            className="inline"
                                        />
                                    </span>
                                </div>
                                {otpModes?.some(element => element.name === 'email') && 
                                    <Text
                                        content={maskEmail(otpModes.find(element => element.name === 'email').to)}
                                        className={`mt-2 p5-medium ${
                                            isTaxOptimiserLogin
                                                ? 'text-basicWhite'
                                                : 'text-basicBlack'
                                        }`}
                                    />
                                }
                                {userInput?.documentId && (
                                    <Text
                                        content={`For PAN ******${userInput.documentId.substr(
                                            -4
                                        )}`}
                                        className={`my-5 ${
                                            isTaxOptimiserLogin
                                                ? 'text-basicWhite'
                                                : 'text-gray-900'
                                        } p5-medium`}
                                    />
                                )}
                            </div>
                            <div className="w-full text-center">
                                <div className="flex justify-center my-5 md:my-7">
                                    {OtpInputComponent}
                                </div>
                                <Button
                                    id="verifyOtpButton"
                                    buttonType="primary"
                                    widthClass="w-full"
                                    className={`mt-4 ${
                                        isTaxOptimiserLogin && (otpValue.length !== otpLength || !!message)
                                            ? 'bg-gray-400 text-gray-600'
                                            : ''
                                    }`}
                                    isLoading={loading}
                                    disabled={
                                        (otpValue.length !== otpLength || !!message) && 'disabled'
                                    }
                                    buttonText="Verify"
                                />
                                {message ? (
                                    <Text
                                        content={message}
                                        className="mt-3 text-center text-semantic-error-base p5-medium"
                                    />
                                ) : (
                                    otpMessage != '' && (
                                        <h1 className="mt-3 text-center p5-medium text-secondary-600">
                                            {otpMessage}
                                        </h1>
                                    )
                                )}
                                <div className="justify-between pt-2 pb-3 p5-regular">
                                    <div className='flex justify-center'>
                                        <Button buttonText={"Resend OTP"}
                                            onClick={onResendOtp}
                                            buttonType={'link'}
                                            className={`!justify-center ${seconds !== 0
                                                ? 'hidden'
                                                : ''
                                                }`}
                                            buttonSize={'extrasmall'}
                                            isLoading={isResendButtonLoading}
                                            widthClass={
                                                isTaxOptimiserLogin
                                                    ? '!text-primary-40'
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className="w-full">
                                        {seconds != 0 && (
                                            <Text
                                                className="p5-regular"
                                                content={`Resend OTP in 00:${seconds < 10
                                                    ? `0${seconds}`
                                                    : `${seconds}`
                                                    } Seconds`}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                }
            />
        </>
    );
};
export default VerifyOtpModal;
